import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Homepage2.css"
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import { useStore } from "../../../store/store";
import "./Component2/ShopbyCategory.css";
import { Helmet } from "react-helmet-async";
import Component2 from "./NewComponent/Component2/Component2";
import Component1 from "./NewComponent/Component1/Component1";
import Component4 from "./NewComponent/Component4/Component4";
import Component3 from "./NewComponent/Component3/Component3";
import Component5 from "./NewComponent/Component5/Component5";
import Component6 from "./NewComponent/Component6/Component6";
import Component7 from "./NewComponent/Component7/Component7";
import Component9 from "./NewComponent/Component9/Component9";
import RecentlyViewedItems from "./NewComponent/RecentlyViewedItems/RecentlyViewedItems";
import Component8 from "./NewComponent/Component8/Component8";
import { useMediaQuery } from "react-responsive";
import BottomNavbar from "./NewComponent/Component1/BottomNavbar/BottomNavbar";
import 'swiper/css';
import 'swiper/css/effect-cards';

function Homepage() {
    const [state, dispatch] = useStore();
    const [fetchedData, setFetchedData] = useState([]);

    let isMobile = useMediaQuery({
        query: "(max-width:576px)",
    });

    useEffect(() => {
        async function apiCall() {
            document.title = `PLYPICKER`;
            const res = await axios.get(`${new URL(API_URL).origin}/assets/homepage-web.json`);
            // dispatch(setHomeData(res.data));
            setFetchedData(res.data);
        }
        apiCall();
    }, []);
    const [isPhoneActive, setIsPhoneActive] = useState(false);

    const togglePhone = () => {
        setIsPhoneActive(!isPhoneActive);
    };


    function setFilter(filterName) {
        const data = fetchedData.filter((e) => e.type === filterName);
        return data;
    }

    return (
        <>
            <Helmet>
                <meta
                    name="title"
                    content="Online shopping site for buying Laminate, Plywood, wires, sanitary, hardware, and services like carpentry plumbing and more - Best offer!"
                />
                <meta
                    name="description"
                    content="Maharastras biggest e-commerce for buying Laminate, Plywood, wires, tiles, basin, sanitary, hardware, screws and services like carpentry, plumbin, fabrication and more."
                />
                <meta
                    name="keywords"
                    content="plypicker, ecommerce, interior designer, interior designing, plywood, basin, faucet, hardware, flat, wardrobe, bathroom, kitchen"
                />
                <meta property="og:image" content="LOGO" />
                <link rel="canonical" href="https://plypicker.com/" />
            </Helmet>
            <div className={isPhoneActive ? "active-phone-container phone-container d-flex flex-row align-items-center justify-content-start" : "phone-container d-flex flex-row align-items-center justify-content-start"} style={{ cursor: "pointer" }}>
                <div className="phone-icon" onClick={() => setIsPhoneActive(!isPhoneActive)}>
                    <img style={{ margin: "auto", width: "75%", aspectRatio: "1" }} src="https://plypicker-banners.s3.ap-south-1.amazonaws.com/home_page/Customer_Support/call-animation.gif" alt="Phone Icon" className="phone-img" />
                </div>
                <a className="phone-detail px-2 d-flex flex-column align-items-center justify-content-center" href="tel:18002088886">
                    <a style={{ color: "black", textDecoration: "none" }} href="tel:18002088886" className="m-0 p-0 newText text-center">18002088886</a>
                    <a style={{ color: "black", textDecoration: "none" }} href="tel:18002088886" className="m-0 p-0 newText text-center">We are waiting for your call!</a>
                </a>
            </div>
            <Component1 data={fetchedData["component-1"]} navbar={fetchedData["navbar"]} />
            <div className="w-100 px-sm-3 px-2">
                <Component2 data={fetchedData["component-2"]} navbar={fetchedData["navbar"]} />
            </div>
            {state.authenticated && <div className="d-sm-none d-block w-100 px-sm-3 px-2">
                <RecentlyViewedItems />
            </div>}
            <div className="w-100 px-sm-3 px-2">
                <Component3 data={fetchedData["component-3"]} comp2={fetchedData["component-2"]} />
            </div>
            <div className="d-block w-100 px-sm-3 px-2">
                <Component4 data={fetchedData["component-4"]} />
            </div>
            <div className="w-100 px-sm-3 px-2">
                <Component5 data={fetchedData["component-5"]} />
            </div>
            {state.authenticated && <div className="d-sm-block d-none w-100 px-sm-3 px-2">
                <RecentlyViewedItems />
            </div>}
            <div className="w-100 px-sm-3 px-2">
                <Component6 data={fetchedData["component-6"]} />
            </div>
            <div className="d-block w-100 px-sm-3 px-2">
                <Component8 data={fetchedData["component-8"]} />
            </div>
            <div className="d-lg-none d-block w-100 px-sm-3 px-2">
                <Component7 data={fetchedData["component-2"]} />
            </div>
            <div className="w-100 px-sm-3 px-2">
                <Component9 props={fetchedData["component-9"]} />
            </div>
            {/* {isMobile && <BottomNavbar />} */}
        </>
    );
}

export default Homepage;
